exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activation-js": () => import("./../../../src/pages/activation.js" /* webpackChunkName: "component---src-pages-activation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-success-freespin-js": () => import("./../../../src/pages/success-freespin.js" /* webpackChunkName: "component---src-pages-success-freespin-js" */),
  "component---src-templates-category-loadmore-js": () => import("./../../../src/templates/category/loadmore.js" /* webpackChunkName: "component---src-templates-category-loadmore-js" */),
  "component---src-templates-category-promotions-js": () => import("./../../../src/templates/category/promotions.js" /* webpackChunkName: "component---src-templates-category-promotions-js" */),
  "component---src-templates-category-qa-js": () => import("./../../../src/templates/category/qa.js" /* webpackChunkName: "component---src-templates-category-qa-js" */),
  "component---src-templates-category-search-js": () => import("./../../../src/templates/category/search.js" /* webpackChunkName: "component---src-templates-category-search-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-banking-js": () => import("./../../../src/templates/page/banking.js" /* webpackChunkName: "component---src-templates-page-banking-js" */),
  "component---src-templates-page-comments-page-js": () => import("./../../../src/templates/page/comments_page.js" /* webpackChunkName: "component---src-templates-page-comments-page-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page/contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-download-js": () => import("./../../../src/templates/page/download.js" /* webpackChunkName: "component---src-templates-page-download-js" */),
  "component---src-templates-page-game-js": () => import("./../../../src/templates/page/game.js" /* webpackChunkName: "component---src-templates-page-game-js" */),
  "component---src-templates-page-games-js": () => import("./../../../src/templates/page/games.js" /* webpackChunkName: "component---src-templates-page-games-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-regular-js": () => import("./../../../src/templates/page/regular.js" /* webpackChunkName: "component---src-templates-page-regular-js" */),
  "component---src-templates-post-promotion-js": () => import("./../../../src/templates/post/promotion.js" /* webpackChunkName: "component---src-templates-post-promotion-js" */),
  "component---src-templates-post-qa-js": () => import("./../../../src/templates/post/qa.js" /* webpackChunkName: "component---src-templates-post-qa-js" */),
  "component---src-templates-post-regular-js": () => import("./../../../src/templates/post/regular.js" /* webpackChunkName: "component---src-templates-post-regular-js" */)
}

